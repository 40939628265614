import Flickity from "flickity";
require('./flickity-fading');

document.addEventListener('DOMContentLoaded', function() {

  let clientsCarouselInstances = [];
  let propertyCarouselInstances = [];
  let heroCarouselInstances = [];
  let servicesInstances = [];

  const clientsCarousels = document.querySelectorAll('.js-clients-carousel');
  const propertyCarousels = document.querySelectorAll('.property-carousel');
  const heroCarousels = document.querySelectorAll('.hero-bg-carousel');
  const services = document.querySelectorAll('.ServicesSliderBlock__wrapper');
  const progressBar = document.querySelector('.progress-bar');

  function initializeSliders() {
    clientsCarouselInstances.forEach(instance => instance.destroy());
    propertyCarouselInstances.forEach(instance => instance.destroy());
    heroCarouselInstances.forEach(instance => instance.destroy());
    servicesInstances.forEach(instance => instance.destroy());

    clientsCarouselInstances = [];
    propertyCarouselInstances = [];
    heroCarouselInstances = [];
    servicesInstances = [];

    if (services) {
      services.forEach(function (elem) {
        if (elem) {
          let carouselOptions = {
            wrapAround: false,
            groupCells: false,
            contain: true,
            cellAlign: 'left',
            pageDots: false,
          };

          const flkty = new Flickity(elem, carouselOptions);
          servicesInstances.push(flkty);

          flkty.on('scroll', function(progress) {
            progress = Math.max(0, Math.min(1, progress));
            if (progressBar) progressBar.style.width = progress * 100 + '%';
          });
        }
      });
    }

    if (clientsCarousels) {
      clientsCarousels.forEach(function (elem) {
        if (elem) {
          let carouselOptions = {
            pageDots: false,
            groupCells: true,
            cellAlign: 'left',
          };

          const flkty = new Flickity(elem, carouselOptions);
          clientsCarouselInstances.push(flkty);
        }
      });
    }

    if (propertyCarousels) {
      propertyCarousels.forEach(function(elem) {
        if (elem) {
          let carouselOptions = {
            prevNextButtons: false,
            draggable: true,
            autoPlay: 6000,
            pageDots: false,
          };

          const flkty = new Flickity(elem, carouselOptions);
          propertyCarouselInstances.push(flkty);
        }
      });
    }


    if (heroCarousels) {
      heroCarousels.forEach(function(elem) {
        if (elem) {
          let carouselOptions = {
            prevNextButtons: false,
            draggable: true,
            autoPlay: 9000,
            fade: true,
            pageDots: false,
          };

          const flkty = new Flickity(elem, carouselOptions);
          heroCarouselInstances.push(flkty);
        }
      });
    }
  }

  initializeSliders();

  let resizeTimeout;
  window.addEventListener('resize', function() {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(initializeSliders, 200);
  });
});
