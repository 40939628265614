 document.addEventListener('DOMContentLoaded', function() {
		const staffNavLink = document.querySelectorAll('.staff-nav-link');

		if(staffNavLink) {
			staffNavLink.forEach(function(link) {
				link.addEventListener('click', function() {
					var filterValue = this.getAttribute('data-filter');
	
					if (filterValue === "all") {
						document.querySelectorAll('.staff-member-item').forEach(function(item) {
							item.style.display = 'block';
						});
					} else {
						document.querySelectorAll('.staff-member-item').forEach(function(item) {
							item.style.display = 'none';
						});
						document.querySelectorAll('.' + filterValue).forEach(function(item) {
							item.style.display = 'block';
						});
					}
		
					staffNavLink.forEach(function(link) {
						link.classList.remove('active');
					});
					this.classList.add('active');
				});
			});
		}

       
    });