import NiceSelect from "nice-select2/dist/js/nice-select2";

document.addEventListener('DOMContentLoaded', function () {
    const dropdowns = document.querySelectorAll('[data-select2]');
    const urlParams = new URLSearchParams(window.location.search);

    let categoryDropdown, bedroomsDropdown, maxPriceDropdown;

    // Initialize NiceSelect for all dropdowns
    dropdowns.forEach(dropdown => {
        const niceSelect = new NiceSelect(dropdown);

        if (dropdown.id === 'category') {
            categoryDropdown = niceSelect;
        }
        if (dropdown.id === 'min-bedrooms') {
            bedroomsDropdown = niceSelect;
        }
        if (dropdown.id === 'max-price') {
            maxPriceDropdown = niceSelect;
        }

        let isOptionPreselected = false;

        urlParams.forEach((value, key) => {
            if (
                (key === 'Category' && dropdown.id === 'category') ||
                (key === 'MinBedrooms' && dropdown.id === 'min-bedrooms') ||
                (key === 'MaxPrice' && dropdown.id === 'max-price') ||
                (key === 'Sort' && dropdown.id === 'sort')
            ) {
                setSelectedOption(niceSelect, value);
                isOptionPreselected = true;
            }
        });

        if (!isOptionPreselected) {
            const allOption = niceSelect.el.querySelector('option[value=""]');
            if (allOption) {
                setSelectedOption(niceSelect, "");
            }
        }

        niceSelect.el.addEventListener('change', () => {
            clearAllSelections(niceSelect);
        });

        niceSelect.el.addEventListener('change', (e) => {
            const value = e.target.value;
            setSelectedOption(niceSelect, value);
        });
    });

    if (maxPriceDropdown && !urlParams.has('MaxPrice')) {
        setSelectedOption(maxPriceDropdown, '900000');
    }

    if (bedroomsDropdown && !urlParams.has('MinBedrooms')) {
        setSelectedOption(bedroomsDropdown, '1');
    }

    if (categoryDropdown && bedroomsDropdown) {
        categoryDropdown.el.addEventListener('change', function () {
            const selectedCategory = categoryDropdown.el.value;

            if (selectedCategory === 'Building Plots') {
                setSelectedOption(bedroomsDropdown, '0');
            } else {
                const currentValue = bedroomsDropdown.el.value;
                setSelectedOption(bedroomsDropdown, currentValue);
            }
        });
    }
});

// Function to clear all selections for the specific dropdown
function clearAllSelections(niceSelectInstance) {
    const options = niceSelectInstance.el.querySelectorAll('option');
    const optionElements = niceSelectInstance.dropdown.querySelectorAll('.option');

    options.forEach(opt => opt.removeAttribute('selected'));
    optionElements.forEach(opt => opt.classList.remove('selected'));

    niceSelectInstance.update();
}

// Function to update the selected option
function setSelectedOption(niceSelectInstance, value) {
    const options = niceSelectInstance.el.querySelectorAll('option');
    const optionElements = niceSelectInstance.dropdown.querySelectorAll('.option');

    options.forEach(opt => opt.removeAttribute('selected'));
    optionElements.forEach(opt => opt.classList.remove('selected'));

    const option = niceSelectInstance.el.querySelector(`option[value="${value}"]`);
    if (option) {
        option.setAttribute('selected', 'selected');
    }

    niceSelectInstance.update();

    const liOption = niceSelectInstance.dropdown.querySelector(`.option[data-value="${value}"]`);
    if (liOption) {
        liOption.classList.add('selected');
    }
}
