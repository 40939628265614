document.addEventListener('DOMContentLoaded', function() {
    const imagebtn = document.querySelector(".image-buttons__images");
    const floorplanbtn = document.querySelector(".image-buttons__floorPlans");
    const mapbtn = document.querySelector(".image-buttons__map");

    function changeContent(contentType) {
        var sections = document.querySelectorAll('#contentContainer > div');
        sections.forEach(function(section) {
            section.classList.remove('active');
        });

        switch (contentType) {
            case 'images':
                document.querySelector('.images-section').classList.add('active');
                floorplanbtn.classList.remove('active');
                mapbtn.classList.remove('active');
                imagebtn.classList.add('active');
                break;
            case 'floorplans':
                document.querySelector('.floorplan-section').classList.add('active');
                imagebtn.classList.remove('active');
                mapbtn.classList.remove('active');
                floorplanbtn.classList.add('active');
                break;
            case 'map':
                document.querySelector('.map-section').classList.add('active');
                imagebtn.classList.remove('active');
                floorplanbtn.classList.remove('active');
                mapbtn.classList.add('active');
                break;
        }
    }

    if (imagebtn) {
        imagebtn.addEventListener("click", function() {
            changeContent('images');
        });
    }

    if (floorplanbtn) {
        floorplanbtn.addEventListener("click", function() {
            changeContent('floorplans');
        });
    }

    if (mapbtn) {
        mapbtn.addEventListener("click", function() {
            changeContent('map');
        });
    }
});