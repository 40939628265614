document.addEventListener('DOMContentLoaded', function() {
    let currentCategory = 'all';
    let currentPage = 1;

    const newsArticle = document.querySelectorAll('.news-article');
    const newsCategory = document.querySelectorAll('.news-category');
    const articlesContainer = document.querySelector('.news-articles-grid');
    const noArticlesMessage = document.createElement('div');
    noArticlesMessage.classList.add('no-articles');
    noArticlesMessage.textContent = 'No articles available.';

    // Append noArticlesMessage to articlesContainer
    if (articlesContainer) {
        articlesContainer.appendChild(noArticlesMessage);
    }

    function getArticlesPerPage() {
        // Return 8 articles for the first page, 9 for others
        return currentPage === 1 ? 8 : 9;
    }

    function renderArticles() {
        const articlesPerPage = getArticlesPerPage();
        const filteredArticles = Array.from(newsArticle).filter(function(article) {
            const categories = article.dataset.categoryId.toString().split(' ');
            return currentCategory === 'all' || categories.includes(currentCategory);
        });

        const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);
        const startIndex = (currentPage - 1) * articlesPerPage;
        const endIndex = startIndex + articlesPerPage;

        Array.from(newsArticle).forEach(function(article) {
            article.style.display = 'none';
        });

        if (filteredArticles.length === 0) {
            noArticlesMessage.style.display = 'block';
        } else {
            noArticlesMessage.style.display = 'none';
            filteredArticles.slice(startIndex, endIndex).forEach(function(article) {
                article.style.display = 'block';
            });
        }

        renderPagination(totalPages);
    }

    function renderPagination(totalPages) {
        const paginationContainer = document.querySelector('.pagination');
        if (paginationContainer) {
            paginationContainer.innerHTML = '';
    
            if (currentPage > 1) { // Check if it's not the first page
                const prevLink = document.createElement('a');
                prevLink.href = '#';
                prevLink.classList.add('prev');
                prevLink.textContent = 'Previous';
                prevLink.addEventListener('click', function(e) {
                    e.preventDefault();
                    currentPage--;
                    renderArticles();
                });
                paginationContainer.appendChild(prevLink);
            }
    
            for (let i = 1; i <= totalPages; i++) {
                const pageLink = document.createElement('a');
                pageLink.href = '#';
                pageLink.classList.add('page-link');
                pageLink.textContent = i;
                pageLink.addEventListener('click', function(e) {
                    e.preventDefault();
                    currentPage = i;
                    renderArticles();
                });
                paginationContainer.appendChild(pageLink);
    
                if (i === currentPage) {
                    pageLink.classList.add('current');
                }
            }
    
            if (currentPage < totalPages) {
                const nextLink = document.createElement('a');
                nextLink.href = '#';
                nextLink.classList.add('next');
                nextLink.textContent = 'Next';
                nextLink.addEventListener('click', function(e) {
                    e.preventDefault();
                    currentPage++;
                    renderArticles();
                });
                paginationContainer.appendChild(nextLink);
            }
        }
    }

    newsCategory.forEach(function(category) {
        category.addEventListener('click', function() {
            const categoryId = this.dataset.categoryId;
            Array.from(newsCategory).forEach(function(category) {
                category.classList.remove('active');
            });
            this.classList.add('active');
            currentCategory = categoryId;
            currentPage = 1; // Reset to first page when category changes
            renderArticles();
        });
    });

    renderArticles();
});
