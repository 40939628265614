import GLightbox from 'glightbox';

document.addEventListener('DOMContentLoaded', function() {
    const lightbox = document.querySelector(".js-glightbox-modal");

    if (lightbox) {
        // Initialize GLightbox
    const lightbox = GLightbox({
        selector: '.js-glightbox-modal',
        touchNavigation: true, // Enable touch gestures for navigation
        loop: true, // Loop through images
        autoplayVideos: true, // Autoplay videos in the gallery
        slideEffect: 'fade'
    });

    // Assuming your lightbox button triggers the gallery
    const lightboxBtn = document.querySelector('.lightbox-btn');

    lightboxBtn.addEventListener('click', function(event) {
        event.preventDefault();
        // Open the GLightbox gallery programmatically
        lightbox.open();
    });
    }
    
});