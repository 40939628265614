$('.dropdown-section').hover(function() {
	var left_list = $(this).parent(),
		right_list = $(this).find('ul');


	left_list.height('auto');
	right_list.height('auto');

	var max = Math.max(left_list.height(), right_list.height());

	left_list.height(max);
	right_list.height(max);

});