document.addEventListener("DOMContentLoaded", function() {
    // Initialize Owl Carousel
    var owl = document.querySelector('.home-slider');
    if (owl) {
        $(owl).owlCarousel({
            navigation: true, // Show next and prev buttons
            slideSpeed: 300,
            paginationSpeed: 400,
            singleItem: true,
            autoHeight: true,
            autoPlay: true,
            navigationText: ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>'],
            transitionStyle: "fade"
        });
    }

    // Change background images
    var heroSection = document.getElementById('hero-section');
    if (heroSection) {
        var images = JSON.parse(heroSection.getAttribute('data-images'));
        var currentIndex = 0;

        heroSection.style.backgroundImage = 'url(' + images[currentIndex] + ')';

        function changeBackgroundImage() {
            currentIndex = (currentIndex + 1) % images.length;
            heroSection.style.backgroundImage = 'url(' + images[currentIndex] + ')';
        }

        setInterval(changeBackgroundImage, 4000);
    }
});
