require('./app/animations');
require('./app/header');
require('./app/home');
require('./app/property');
require('./app/property-category');
require('./app/section-nav');
require('./app/sitemap');
require('./app/staffpage');
require('./app/site-nav');
require('./app/lazy-load');
require('./app/flickity');
require('./app/newsfilter');
require('./app/propertyPage');
require('./app/glightbox');
require('./app/flickity-fading');
require('./app/mobileSubHeader');
require('./app/selectDropdown');

