import LazyLoad from 'vanilla-lazyload';

let lazyLoadInst = null;

document.addEventListener('DOMContentLoaded', function() {
    if (!lazyLoadInst) {
        lazyLoadInst = new LazyLoad({
            elements_selector: '.lazy',
            threshold: 0,
        });
    }

    lazyLoadInst.update();
    window.addEventListener('ajaxupdated', () => {
        lazyLoadInst.update();
    });
});
