$(document).ready(function() {

	var sync1 = $("#property-gallery");
	var sync2 = $("#property-gallery-nav");
 
  	sync1.owlCarousel({
		singleItem 	: true,
		slideSpeed 	: 500,
		navigation 	: true,
		lazyLoad	: true,
		pagination	: false,
		afterAction	: syncPosition,
		responsiveRefreshRate : 200,
		navigationText: ["<i class='icon-angle-left'></i>","<i class='icon-angle-right'></i>"]
  	});
 
  	sync2.owlCarousel({
		items : 5,
		itemsDesktopSmall : [979,5],
		itemsTablet       : [768,4],
		itemsMobile       : [479,3],
		lazyLoad:true,
		pagination:false,
		responsiveRefreshRate : 200,
		afterInit : function(el){
		  	el.find(".owl-item").eq(0).addClass("synced");
		}
  	});
 
  	function syncPosition(el){
		var current = this.currentItem;
		sync2
	  		.find(".owl-item")
	  		.removeClass("synced")
	  		.eq(current)
	  		.addClass("synced")
		if(sync2.data("owlCarousel") !== undefined){
	  		center(current)
		}
  	}
 
  	sync2.on("click", ".owl-item", function(e){
		e.preventDefault();
		var number = $(this).data("owlItem");

		sync1.trigger("owl.goTo",number);
  	});
 
  	function center(number){
		var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
		var num = number;
		var found = false;
		for(var i in sync2visible){
	  		if(num === sync2visible[i]){
				var found = true;
	  		}
		}
 
		if(found===false){
	  		if(num>sync2visible[sync2visible.length-1]){
				sync2.trigger("owl.goTo", num - sync2visible.length+2)
	  		}else{
				if(num - 1 === -1){
	  				num = 0;
				}
				sync2.trigger("owl.goTo", num);
	  		}
		} else if(num === sync2visible[sync2visible.length-1]){
	  		sync2.trigger("owl.goTo", sync2visible[1])
		} else if(num === sync2visible[0]){
	  		sync2.trigger("owl.goTo", num-1)
		}
  	}


  	//floorplans
  	$('.property-floorplans').owlCarousel({
		singleItem 	: true,
		slideSpeed 	: 500,
		navigation 	: true,
		lazyLoad	: true,
		pagination	: false,
		responsiveRefreshRate : 200,
		navigationText: ["<i class='icon-angle-left'></i>","<i class='icon-angle-right'></i>"]
  	});


  	$('.property-tab-content:not(.current)').hide();

	$('.property-tabs a').click(function(e) {
		e.preventDefault();

		var id = $(this).attr('href').substr($(this).attr('href').indexOf("#") + 1);

		//hide all tabs and remove all .current from tabset
		$(this).siblings().each(function() {
			$(this).removeClass('current');
			var sibling_id = $(this).attr('href').substr($(this).attr('href').indexOf("#") + 1);
			$("#"+sibling_id).hide();
		});

		//show tabs and add .current
		$(this).addClass('current');
		$("#"+id).show();
	});

	$('.property-tabs > a:first-child').click();


});