document.addEventListener('DOMContentLoaded', function() {
    const secondaryImage = document.querySelectorAll('.mosaicimageblock__secondary-image');
    const thirdImage = document.querySelectorAll('.mosaicimageblock__third-image');
    const mainImage = document.querySelectorAll('.mosaicimageblock__main-image');
    const slideLeft = document.querySelectorAll('.mosaicimageblock__secondary-image.slide-in-left');
    const slideRight = document.querySelectorAll('.mosaicimageblock__third-image.slide-in-right');
    const slideBottom = document.querySelectorAll('.mosaicimageblock__main-image.slide-in-bottom');

    function addAnimationClass(entries, observer) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('animated');
                observer.unobserve(entry.target); // Stop observing once animated
            }
        });
    }

    const observerOptions = {
        threshold: 0.1 // Adjust based on when you want the animation to trigger
    };

    const observer = new IntersectionObserver(addAnimationClass, observerOptions);

    if (secondaryImage) {
        secondaryImage.forEach(function(element) {
            observer.observe(element);
            element.classList.add('slide-in-left');
        });
    }

    if (thirdImage) {
        thirdImage.forEach(function(element) {
            observer.observe(element);
            element.classList.add('slide-in-right');
        });
    }

    if (mainImage) {
        mainImage.forEach(function(element) {
            observer.observe(element);
            element.classList.add('slide-in-bottom');
        });
    }
   
    if (slideLeft) {
        slideLeft.forEach(function(element) {
            element.classList.add('animated');
        });
    }

    if (slideBottom) {
        slideBottom.forEach(function(element) {
            element.classList.add('animated');
        });
    }

    if (slideRight) {
        slideRight.forEach(function(element) {
            element.classList.add('animated');
        });
    }
     
});
