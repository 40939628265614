$(document).ready(function() {
	$('.property-search-bar .fieldgroup > label').each(function(){
		$(this).append('<i class="icon-angle-down"></i>');
	}).click(function() {
		$(this).parent()
			.toggleClass('is-open')
			.siblings().removeClass('is-open');
	});

	$('[name="action_doFilter"]').hide();
	$('.property-search-bar form').change(function() {
		$('[name="action_doFilter"]').show();
	});
});