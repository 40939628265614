$(document).ready(function() {
	$('.site-nav-toggle').click(function(e) {
		$('.site-nav').toggleClass('is-open');
		e.preventDefault();
	});

	$('.site-search-toggle').click(function(e) {
		$('.site-search').toggleClass('is-open');
		e.preventDefault();
	})	
});

$(document).ready(function(){

	$('.NewsBlock .non-news').addClass('idle');
	$('.NewsBlock .news').addClass('active');

	$('.NewsBlock h4.non-news').click(function(){

		$(this).addClass('active');
		$('.NewsBlock .non-news').removeClass('idle');
		$('.NewsBlock h4.news').removeClass('active');

		$('.NewsBlock ul.news').removeClass('active');
		$('.NewsBlock ul.non-news').addClass('active');

	});

	$('.NewsBlock h4.news').click(function(){

		$(this).addClass('active');
		$('.NewsBlock .non-news').removeClass('idle');
		$('.NewsBlock h4.non-news').removeClass('active');

		$('.NewsBlock ul.non-news').removeClass('active');
		$('.NewsBlock ul.news').addClass('active');

	});

});