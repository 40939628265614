document.addEventListener('DOMContentLoaded', function () {
    // Select all elements with the attribute 'mobile-main-nav'
    const mainNavItems = document.querySelectorAll('[mobile-main-nav]');

    mainNavItems.forEach(function (item) {
        const link = item.querySelector('.site-nav-link');
        const dropdown = item.querySelector('.dropdown');
        const hasDropdown = dropdown !== null;
        item.clickedOnce = false;  // Store state in the item itself

        link.addEventListener('click', function (e) {
            if (hasDropdown) {
                if (!item.clickedOnce) {
                    // Prevent default action and show the dropdown
                    e.preventDefault();
                    item.classList.add('active');
                    dropdown.classList.add('activeDropdown');
                    item.clickedOnce = true;
                } else {
                    // Allow the default action to proceed
                    item.classList.remove('active');
                    dropdown.classList.remove('activeDropdown');
                    item.clickedOnce = false;
                }
            }
        });
    });

    // Select all elements with the class 'dropdown-section'
    const dropdownSections = document.querySelectorAll('.dropdown-section');

    dropdownSections.forEach(function (section) {
        const subNav = section.querySelector('.sub-nav');
        const hasChildren = subNav !== null;
        section.clickedOnce = false;  // Store state in the section itself

        section.addEventListener('click', function (e) {
            if (hasChildren) {
                if (!section.clickedOnce) {
                    // Prevent default action and show the sub-nav
                    e.preventDefault();
                    section.classList.add('active');
                    subNav.style.display = 'block';
                    section.clickedOnce = true;
                } else {
                    // Allow the default action to proceed
                    section.classList.remove('active');
                    subNav.style.display = 'none';
                    section.clickedOnce = false;
                }
            }
        });
    });
});
